.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* My css */
body{
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
h1{
  font-size: 1.7rem !important;
  text-align:center;
}
h2{
  font-size: 1.5rem !important;
  text-align:center;
}
h3{
  font-size: 1rem !important;
  text-align:center;
}
h4{
  font-size: 0.9rem !important;
  text-align:center;
}
h5{
  font-size: 0.8rem !important;
  text-align:center;
}
#Navbar{
  background-color: rgb(0,81,115) ;
}
.content{
  min-height: calc(100vh - 250px);
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.justified{
  text-align: justify;
  text-justify: inter-word;
  margin-left: 5vh;
  margin-right: 5vh;
}
.headline-top{
  margin-top: 1vh;
}
.about{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-end;
}
.about-image{
  min-width: 20rem;
}

.about-body{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
  align-content: center;
  padding: 0vw 5vw 0vw 0vw;
}
.about-text{
  margin-left: 2px;
  text-align: justify;
  text-justify: inter-word;
  align-items: baseline;
  
}
.about-card{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.carousel{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  align-content: center;
  width: 100vw;
  height: 80vh;
}
.carousel-image{
  width :100vw;
  height:100vh;
}
.img1{
  content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609263588/myFolder/IMG_9397.JPG.jpg");
}
.img2{
  content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609329448/myFolder/IMG_9490.JPG.jpg");
}
.img3{
  content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609262745/myFolder/IMG_9518.JPG.jpg");
}
.infobox{
  font-size: small;
}
.row-buttons{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.btn-crud{
  margin: 5px 5px 5px 5px;
  background-color:  rgb(0,81,115) !important;
  color: white !important;
}
.date-share{
  margin-right: 6%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
}
.border-meal{
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid rgb(0,81,115)
}
.card-deck{
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-top: 25px;
}
.store{
  margin-top: 10px;
}
.card{
  border: none!important ; 
  min-width:"18rem"!important
}
.card-img-top-blue{
  width: 80%!important;
  height: auto;
  
}
.card-img-top{
  width: 80%!important;
  height: auto;
}

.card-body{
  margin-top: -5px;
}
.product{
  margin-top: 25px;
}
.category-content-deck{
  display: flex; 
	flex-direction: row!important; 
	flex-wrap: wrap!important;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.meal{
  margin: 5px 5px 5px 5px;
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  align-content: center;
}
.category{
  margin: -5px 5px 5px 5px;
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.logo {
  width: 240.6px;
  height: 76.2px;
  margin-right: 10px;
}
#logo-id{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
  align-content: center;
  padding-right:15px;
  font-size: 1.5rem;
  margin-right:10px;
  border-right:solid;
  border-color:gray;
  border-width:thin;
}
#collapsed-links{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
  font-size: 1.5rem;

}
#collapsed-links a{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
  font-size: 1.35rem;
  margin: 5px 5px 5px 5px;
}

footer{
  background-color:  rgb(0,81,115);
  flex-shrink: 0;
  color :white;
}

#logo-link{
  color:white;
  text-decoration-line: none;
}
.navlinks{
  color: white;
  transition: 0.8s;
}
.navlinks:hover{
 color:gray;
 
}
.selected{
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: gray!important;
  color:white !important;
}
.footer-link{
  color:gray;
}
.footer-link:hover{
  color:lightblue!important;
}

.contacts{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}
.contact-info{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

#mapa{
  margin-top: 35px;
  width: 800px!important;
  height: 600px!important;
}
/* Extra small devices (phones, 600px and down) */
@media (min-width: 0px) and (max-width: 600px) {

  h1{
    font-size: 1.2rem !important;
    text-align:center;
  }
  h2{
    font-size: 1rem !important;
    text-align:center;
  }
  h3{
    font-size: 0.7rem !important;
    text-align:center;
  }
  h4{
    font-size: 0.6rem !important;
    text-align:center;
  }
  h5{
    font-size: 0.5rem !important;
    text-align:center;
  }
  .logo {
    width: 171.857px;
    height: 54.42857px;
    }
    #logo-id{
      margin-left: -10px;
      margin-right: 0px;
      padding-right: 0px;
      font-size: 0.9rem;
      border-right:gray;
    }
    .about-image{
      min-width: 18rem;
    }
    .about-card{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      align-content: center;
    }
    .center{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .img1{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1610476927/myFolder/IMG_9518_3.jpg.jpg");
    }
    .img2{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1610476917/myFolder/IMG_9490_3.jpg.jpg");
    }
    .img3{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1610476877/myFolder/IMG_9397_3.jpg.jpg");
    }
  
    #collapsed-links{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    #collapsed-links a{
      padding-left: 2%;
      padding-right: 2%;
      font-size: 1rem;
    }
    #mapa{
      width: 300px!important;
      
    } 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  body{
    display: flex;
    flex-direction: column;
  }
  h1{
    font-size: 1.2rem !important;
    text-align:center;
  }
  h2{
    font-size: 1.1rem !important;
    text-align:center;
  }
  h3{
    font-size: 0.8rem !important;
    text-align:center;
  }
  h4{
    font-size: 0.7rem !important;
    text-align:center;
  }
  h5{
    font-size: 0.5rem !important;
    text-align:center;
  }
  .logo {
      width: 171.857px;
      height: 54.42857px;
  
    }
    #logo-id{
      margin-left: -15px;
      font-size: 1.2rem;
      border-right:none;
    }
    .about-image{
      min-width: 18rem;
    }
    .about-card{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      align-content: center;
    }
    .center{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .img1{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609327942/myFolder/IMG_9397_2.jpg.jpg");
    }
    .img2{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609329231/myFolder/IMG_9490_2.jpg.jpg");
    }
    .img3{
      content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609329039/myFolder/IMG_9518_2.jpg.jpg");
    }
  
    #collapsed-links{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 1.15rem;
      
    }
    #collapsed-links a{
      padding-left: 4%;
      padding-right: 4%;
      font-size: 1.15rem;
      
    } 
  #mapa{
    width: 500px!important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 767px) and (max-width: 992px) {

  h1{
    font-size: 1.7rem !important;
    text-align:center;
  }
  h2{
    font-size: 1.5rem !important;
    text-align:center;
  }
  h3{
    font-size: 1rem !important;
    text-align:center;
  }
  h4{
    font-size: 0.9rem !important;
    text-align:center;
  }
  h5{
    font-size: 0.8rem !important;
    text-align:center;
  }

  .about-image{
    min-width: 18rem;
  }
  .about-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
  }
  .img1{
    content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609263588/myFolder/IMG_9397.JPG.jpg");
  }
  .img2{
    content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609329448/myFolder/IMG_9490.JPG.jpg");
  }
  .img3{
    content:url("https://res.cloudinary.com/dxqsjpa34/image/upload/v1609262745/myFolder/IMG_9518.JPG.jpg");
  }

  .logo {
    width: 200.5px;
    height: 63.5px;
  }
  #logo-id{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-right:15px;
    font-size: 1.5rem;
    margin-right:10px;
    border-right:solid;
    border-color:gray;
    border-width:thin;
  }
  #collapsed-links{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    font-size: 1.5rem;
  
  }
  #collapsed-links a{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    font-size: 1.35rem;
    margin: 5px 5px 5px 5px;
  }

  #mapa{
    margin-top: 35px;
    width: 800px!important;
    height: 600px!important;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {

}
